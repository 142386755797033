import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
// import LanguageForm from "@containers/language-translation/form";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import FaqArea from "@containers/faq/layout-03";
import LanguageTranslationIntro from "@containers/language-translation/intro";
import LanguageKeywords from "@containers/language-translation/keywords";
import LanguageExpertise from "@containers/language-translation/expertise";
import LanguageTranslationTips from "@containers/language-translation/translation-tips";
import LanguageVarieties from "@containers/language-translation/varieties";
import LanguageTranslators from "@containers/language-translation/translators";
import LanguageVoiceovers from "@containers/language-translation/voiceovers";
import LanguageSoftware from "@containers/language-translation/software";
import LanguageGames from "@containers/language-translation/games";
import LanguageServices from "@containers/language-translation/services";
import LanguageDocuments from "@containers/language-translation/documents";
import LanguageFacts from "@containers/language-translation/facts";
import HowWeWork from "@containers/global/how-we-work";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/common-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const ThaiTranslationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Thai Translation Services | Thai Translation Company"
        description="Professional Thai Translation Services. Our Thai Translation Company has over 500 qualified Thai Translators and Editors. Get a Free Quote!"
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["thai-translation-header-section"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
            <FeatureGrid data={content["thai-translation-features-body"]} />
            <PartnerArea data={content["thai-translation-logo-body"]} />
            <LanguageTranslationIntro data={content["thai-translation-intro"]} />
          </>
        }
        {
          showRestComponents && <>
            <LanguageKeywords data={content["thai-translation-keyword-body"]} />
            <LanguageTranslationIntro
              data={content["thai-translation-services"]}
            />
            <LanguageExpertise data={content["thai-translation-expertise-body"]} />
            <LanguageTranslationTips data={content["thai-translation-tip-body"]} />
            <LanguageVarieties data={content["thai-translation-varities-body"]} />
            <LanguageTranslators
              data={content["thai-translation-translator-body"]}
            />
            <LanguageVoiceovers
              dataVoiceOver={content["thai-translation-voiceover-body"]}
              dataWebsite={content["thai-translation-website-body"]}
            />
            <LanguageSoftware data={content["thai-translation-software-body"]} />
            <LanguageGames data={content["thai-translation-games-body"]} />
            <LanguageServices
              intoEnglish={content["thai-into-english-body"]}
              subtitlesData={content["thai-translation-subtitle-body"]}
            />
            <LanguageDocuments
              documentData={content["thai-translation-document-body"]}
              elearningData={content["thai-translation-elearing-body"]}
            />
            <LanguageFacts data={content["thai-translation-facts-body"]} />
            <HowWeWork data={content["howwework"]} />
            <FaqArea data={content["thai-translation-faq-body"]} />
            <CaseStudy data={caseStudiesData} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query thaiTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "thai-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

ThaiTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ThaiTranslationPage;
